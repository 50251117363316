import { db } from "../firebase";
import { collection, getDocs, addDoc } from "firebase/firestore";

// Function to fetch family data from Firebase Firestore
export async function fetchFamilyData() {
  const querySnapshot = await getDocs(collection(db, "family"));
  return querySnapshot.docs.map((doc) => doc.data());
}

export async function AddFamily(name, address, phoneNumber, location, city) {
  const docRef = await addDoc(collection(db, "family"), {
    fullName: name,
    Address: address,
    phoneNumber: phoneNumber,
    location: location,
    city: city,
  });
  return docRef;
}

export async function deleteFamily() {}
