import React from "react";
import { useNavigate } from "react-router-dom";

export default function WelcomePage() {
  const nav = useNavigate();
  return (
    <div className="centered-container">
      <div className="userform">
        <img
          className="logo"
          src="./logo.png"
          alt="logo"
          width={120}
          height={120}
        />
        <button onClick={() => nav("/signup")}>Register</button>
        <button onClick={() => nav("/login")}>Login</button>
      </div>
    </div>
  );
}
