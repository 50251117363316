import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { AuthContext } from "../contexts/authProvider";
import { FaHome, FaSignOutAlt, FaUserPlus } from "react-icons/fa";

export default function Nav() {
  const { logOut, loading } = useContext(AuthContext);
  const navigate = useNavigate();
  const auth = getAuth();

  const handleSignOut = () => {
    logOut()
      .then(() => {
        navigate("/login"); // Redirect to the login page after logout
      })
      .catch((error) => console.error(error));
  };
  return loading ? (
    <span>loading</span>
  ) : (
    <nav>
      <img
        className="logo"
        src="./logo.png"
        alt="logo"
        width={35}
        height={35}
        style={{ marginTop: "15px" }}
      />
      Ahlan wa Sahlah {auth.currentUser.displayName}
      <button onClick={() => navigate("/home")}>
        {" "}
        <FaHome />
        &nbsp; Home
      </button>
      <button onClick={() => navigate("/addMarker")}>
        {" "}
        <FaUserPlus />
        &nbsp; Add Family
      </button>
      <button onClick={handleSignOut}>
        {" "}
        <FaSignOutAlt />
        &nbsp; Logout
      </button>
    </nav>
  );
}
