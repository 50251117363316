import React, { useContext } from "react";
import { AuthContext } from "../contexts/authProvider";
import ZMap from "./ZMap";
import Nav from "./Nav";

function Dashboard({ userEmail }) {
  // Access the user, logOut, and loading state from the AuthContext
  const { loading } = useContext(AuthContext);
  return loading ? (
    <span>loading</span>
  ) : (
    <div>
      <Nav />
      <ZMap />
    </div>
  );
}

export default Dashboard;
