// PrivateRoute.js
import { useContext } from "react";
import { AuthContext } from "../contexts/authProvider";
import { Navigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PrivateRoute = ({ children }) => {
  const { loading, user } = useContext(AuthContext);

  if (loading) {
    return <span>loading</span>;
  }

  if (user && getAuth().currentUser.emailVerified) {
    return children;
  } else if (!user) {
    toast("Please Login First!");
  } else if (!getAuth().currentUser.emailVerified) {
    toast("Please verify your email");
  }

  return (
    <div>
      <Navigate to="/login" />
      <ToastContainer />
    </div>
  );
};

export default PrivateRoute;
