import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../contexts/authProvider";
import fetchAndProcessRecords from "./UpdateFamilyCoordinates";
import Nav from "./Nav";
function Home() {
  const { loading } = useContext(AuthContext);
  const navigate = useNavigate();

  return loading ? (
    <span>loading</span>
  ) : (
    <>
      {" "}
      <Nav />
      <div className="home">
        <button onClick={() => navigate("/dashboard")}>
          Display Mumineen Map
        </button>
        <button onClick={() => fetchAndProcessRecords()}>
          Update Mumineen Records
        </button>
      </div>
    </>
  );
}

export default Home;
