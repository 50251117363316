import React, { useState, useEffect } from "react";
import { getAuth, multiFactor, TotpMultiFactorGenerator } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DisplayTOTPSecret = ({ currentUser }) => {
  const [totpSecret, settotpSecret] = useState(null);
  const [verificationCode, setVerificationCode] = useState("");
  const [url, setUrl] = useState();
  const navigate = useNavigate();
  const auth = getAuth();
  useEffect(() => {
    const getTOTPSecret = async () => {
      try {
        // Get multi-factor session
        const multiFactorSession = await multiFactor(
          auth.currentUser
        ).getSession();

        // Generate TOTP secret
        const secret = await TotpMultiFactorGenerator.generateSecret(
          multiFactorSession
        );
        settotpSecret(secret);
        const totpUri = secret.generateQrCodeUrl(
          currentUser.email,
          "Plan My Trips"
        );
        setUrl(totpUri);
      } catch (error) {
        toast("Error: " + error.message);
        // Handle error
      }
    };

    getTOTPSecret();
  }, [currentUser, auth.currentUser]);

  const handleFinalizeEnrollment = async () => {
    try {
      // Ask the user for the verification code from the OTP app.
      const multiFactorAssertion =
        TotpMultiFactorGenerator.assertionForEnrollment(
          totpSecret,
          verificationCode
        );

      // Finalize the enrollment.
      multiFactor(currentUser).enroll(
        multiFactorAssertion,
        "The Authenticator App"
      );
      navigate("/dashboard");
      // You can navigate or perform other actions after enrollment completion.
    } catch (error) {
      console.error("Error finalizing TOTP enrollment:", error.message);
      toast("Error: " + error.message);
      // Handle error
    }
  };

  return (
    <div className="centered-container">
      <div className="userForm">
        <div>
          <h2>Display TOTP Secret</h2>
          {totpSecret && <p>TOTP Secret: {totpSecret.secretKey}</p>}
          <QRCodeSVG value={url} />
          <h2>Finalize TOTP Enrollment</h2>
          <label>Verification Code:</label>
          <input
            type="text"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
          <br />
          <button onClick={handleFinalizeEnrollment}>
            Complete Enrollment
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default function EnrollTOTP() {
  const user = getAuth().currentUser;

  return (
    <div>
      <DisplayTOTPSecret currentUser={user} />
    </div>
  );
}
