import React from "react";
import { FaWhatsappSquare } from "react-icons/fa";

const FamilyList = ({
  family,
  selectedFamily,
  setSelectedFamily,
  setPosition,
  selectedCity,
  setSelectedCity,
}) => {
  const cities = [...new Set(family.map((person) => person.city))];

  return (
    <div
      className="families"
      style={{
        maxHeight: "50vh",
        overflow: "auto",
        width: "90vw",
        padding: "10px",
        background: "rgba(0, 48, 73, 0.5)",
        borderRadius: "8px",
      }}
    >
      <div className="filter" style={{ marginBottom: "10px" }}>
        <select
          onChange={(e) => setSelectedCity(e.target.value)}
          style={{ padding: "5px", borderRadius: "4px" }}
        >
          <option value="">All Cities</option>
          {cities.map((city) => (
            <option key={city} value={city}>
              {city}
            </option>
          ))}
        </select>
      </div>
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {family
          .filter((person) =>
            selectedCity ? person.city === selectedCity : true
          )
          .map((person) => (
            <div key={person.fullName} style={{ marginBottom: "10px" }}>
              <li
                className="eachFamily"
                onClick={() => {
                  setSelectedFamily(person);
                  setPosition({
                    lat: person.location._lat,
                    lng: person.location._long,
                  });
                }}
                style={{
                  padding: "10px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  color:
                    selectedFamily &&
                    selectedFamily.fullName === person.fullName
                      ? "#669bbc"
                      : "#003049",
                  fontWeight:
                    selectedFamily &&
                    selectedFamily.fullName === person.fullName
                      ? "bolder"
                      : "normal",
                  backgroundColor:
                    selectedFamily &&
                    selectedFamily.fullName === person.fullName
                      ? "#003049"
                      : "#669bbc",
                }}
              >
                HOF Full Name: {person.fullName}
                <br />
                Phone Number: {person.phoneNumber}
                <a
                  href={`https://wa.me/${person.phoneNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  &nbsp;
                  <FaWhatsappSquare color="#003049" size={17} />
                </a>
                <br />
                Address: {person.Address}
                <br />
                City: {person.city}
              </li>
            </div>
          ))}
      </ul>
    </div>
  );
};

export default FamilyList;