import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/authProvider";
import TOTPModal from "./OTPModal"; // Adjust the import based on your file structure
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function LoginPage() {
  const { loginUser } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showTOTPModal, setShowTOTPModal] = useState(false);
  const [res, setRes] = useState();
  const navigate = useNavigate();

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const res = await loginUser(formData.email, formData.password);
    setRes(res);
    if (res === true) {
      // User needs to enroll in multifactor authentication
      navigate("/enrollotp");
    } else if (res.code === "auth/multi-factor-auth-required") {
      // User needs to provide second factor (TOTP)
      setShowTOTPModal(true);
      toast("Please Enter Multi-Factor Code");
    } else {
      toast("Error: " + res.message);
    }
  };

  return (
    <div className="centered-container">
      <div className="userForm">
        <form onSubmit={handleOnSubmit}>
          <h3>Login</h3>
          <label>Email</label>
          <br />
          <input
            type="email"
            name="email"
            required
            placeholder="Email"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
          <br />
          <label>Password</label>
          <br />
          <input
            type="password"
            name="password"
            placeholder="Password"
            required
            value={formData.password}
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
          />
          <button type="submit">Login</button>
          <button
            onClick={() => {
              navigate("/forgotpassword");
            }}>
            Forgot Password
          </button>
          <p>
            Don't have an account with us?
            <button
              onClick={() => {
                navigate("/signup");
              }}>
              Register here
            </button>
          </p>
        </form>
        {showTOTPModal && (
          <TOTPModal res={res} onClose={() => setShowTOTPModal(false)} />
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default LoginPage;
