import React, { useState, useRef } from "react";
import { GeoPoint } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { Autocomplete } from "@react-google-maps/api";
import GoogleGeocodingService from "@qc/google-geocoding-service";
import { AddFamily } from "./firestoreFunctions";

const geocodingService = new GoogleGeocodingService({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
});

function AddMarkerForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    Address: "",
  });

  const autocompleteRef = useRef(null);

  async function handleOnSubmit(e) {
    e.preventDefault();
    try {
      // Get coordinates and city from the address
      const { coordinates, city } = await getLocationFromAddress(
        formData.Address
      );

      if (coordinates && city) {
        // Save the family data with GeoPoint and City to Firestore
        AddFamily(
          formData.fullName,
          formData.Address,
          formData.phoneNumber,
          new GeoPoint(coordinates.lat, coordinates.lng),
          city
        );

        navigate("/dashboard");
      } else {
        console.error("Unable to get coordinates or city from the address");
      }
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  }

  // Function to get location coordinates and city from an address using the Geocoding Service
  async function getLocationFromAddress(address) {
    try {
      const results = await geocodingService.geocode(address);
      // Find the 'locality' type in address_components
      const cityComponent = results?.[0]?.address_components.find((component) =>
        component.types.includes("locality")
      );
      // If 'locality' type is found, use its long_name as the city
      const city = cityComponent ? cityComponent.long_name : null;
      const geometry = results?.[0]?.geometry?.location;
      return geometry && city
        ? { coordinates: { lat: geometry.lat, lng: geometry.lng }, city }
        : {};
    } catch (error) {
      console.error("Error geocoding address:", error);
      return {};
    }
  }

  const inputStyle = {
    marginBottom: "10px",
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    width: "90%",
  };

  return (
    <div
      className="centered-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}>
      <div
        className="userForm"
        style={{
          margin: "20px",
          padding: "20px",
          borderRadius: "8px",
          backgroundColor: "#f0f0f0",
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
          minHeight: "400px",
          minWidth: "300px",
        }}>
        <form onSubmit={handleOnSubmit}>
          <h3>Add Family</h3>
          <label>Full Name</label>
          <input
            type="text"
            name="fullName"
            required
            placeholder="Full Name"
            value={formData.fullName}
            onChange={(e) =>
              setFormData({ ...formData, fullName: e.target.value })
            }
            style={inputStyle}
          />
          <br />
          <label>Phone Number</label>
          <input
            type="number"
            name="phoneNumber"
            placeholder="Phone Number"
            required
            value={formData.phoneNumber}
            onChange={(e) =>
              setFormData({ ...formData, phoneNumber: e.target.value })
            }
            style={inputStyle}
          />
          <br />
          <label>Address</label>
          <Autocomplete
            onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
            options={{
              componentRestrictions: { country: "au" },
            }}
            onPlaceChanged={() => {
              const place = autocompleteRef.current.getPlace();
              if (place && place.formatted_address) {
                setFormData({ ...formData, Address: place.formatted_address });
              } else {
                console.error("Place details are not available");
              }
            }}>
            <input
              type="text"
              name="Address"
              placeholder="Please enter full address with State and Postcode"
              required
              value={formData.Address}
              onChange={(e) =>
                setFormData({ ...formData, Address: e.target.value })
              }
              style={inputStyle}
            />
          </Autocomplete>

          <button
            type="submit"
            style={{
              marginRight: "10px",
              padding: "10px 20px",
              borderRadius: "4px",
              backgroundColor: "#669bbc",
              color: "#fff",
              border: "none",
            }}>
            Add Family
          </button>
          <button
            onClick={() => navigate("/dashboard")}
            style={{
              padding: "10px 20px",
              borderRadius: "4px",
              backgroundColor: "#d9534f",
              color: "#fff",
              border: "none",
            }}>
            Dashboard
          </button>
        </form>
      </div>
    </div>
  );
}

export default AddMarkerForm;
