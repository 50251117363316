import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, Polygon } from "@react-google-maps/api";
import { fetchFamilyData } from "./firestoreFunctions";
import FamilyList from "./FamilyList";

function ZMap() {
  const [family, setFamily] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [selectedFamily, setSelectedFamily] = useState(null);
  const [selectedCity, setSelectedCity] = useState("");
  const [position, setPosition] = useState({
    lat: -33.80743570062853,
    lng: 150.98040528879093,
  });
  const [polygonCoordinates, setPolygonCoordinates] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchFamilyData();
      setFamily(data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchMarkers = () => {
      const markerPromises = family.map((person) => {
        const coordinates = person.location;

        if (coordinates && (!selectedCity || person.city === selectedCity)) {
          const isSelected =
            selectedFamily && selectedFamily.fullName === person.fullName;

          return (
            <Marker
              onClick={() => {
                setSelectedFamily(person);
                setPosition({
                  lat: coordinates._lat,
                  lng: coordinates._long,
                });
              }}
              key={person.fullName}
              position={{ lat: coordinates._lat, lng: coordinates._long }}
              icon={{
                url: isSelected
                  ? "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
                  : "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
              }}
            />
          );
        }

        return null;
      });

      setMarkers(markerPromises.filter(Boolean));
    };

    fetchMarkers();
  }, [family, selectedFamily, selectedCity]);

  useEffect(() => {
    const fetchCityBoundaries = async (city) => {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?city=${city}&country=Australia&format=json&polygon_geojson=1`
        );
        const data = await response.json();      

        if (data && data[0] && data[0].geojson) {
          const coordinates = data[0].geojson.coordinates[0].map((coord) => ({
            lat: coord[1],
            lng: coord[0],
          }));
          setPolygonCoordinates(coordinates);
        }
      } catch (error) {
        console.error("Error fetching city boundaries:", error);
      }
    };

    if (selectedCity) {
      fetchCityBoundaries(selectedCity);
    } else {
      setPolygonCoordinates(null);
    }
  }, [selectedCity]);

  return (
    <div className="mapOC">
      <div style={{ height: "90vh", width: "90vw", marginBottom: "16px" }}>
        <GoogleMap
          zoom={15}
          center={position}
          mapContainerStyle={{ height: "100%", width: "100%" }}
        >
          {markers}
          {polygonCoordinates && (
            <Polygon
              paths={polygonCoordinates}
              options={{
                strokeColor: "green",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "yellow",
                fillOpacity: 0.35,
              }}
            />
          )}
        </GoogleMap>
      </div>
      <FamilyList
        family={family}
        selectedFamily={selectedFamily}
        setSelectedFamily={setSelectedFamily}
        setPosition={setPosition}
        selectedCity={selectedCity}
        setSelectedCity={setSelectedCity}
      />
    </div>
  );
}

export default ZMap;