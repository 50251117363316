import { collection, getDocs, updateDoc, GeoPoint } from "firebase/firestore";
import GoogleGeocodingService from "@qc/google-geocoding-service";
import { db } from "../firebase";

const geocodingService = new GoogleGeocodingService({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
});

export default async function fetchAndProcessRecords() {
  try {
    // Fetch records from the "family" collection
    const recordsQuery = collection(db, "family");
    const querySnapshot = await getDocs(recordsQuery);

    // Process each record
    querySnapshot.forEach(async (doc) => {
      const data = doc.data();

      // Check if location is set to GeoPoint(0, 0)
      if (
        data.location &&
        data.location.latitude === 0 &&
        data.location.longitude === 0
      ) {
        // Use the geocoding function to get new coordinates and city
        const { coordinates, city } = await getLocationFromAddress(
          data.Address
        );

        if (coordinates && city) {
          // Update the record in Firestore with new GeoPoint and City

          await updateDoc(doc.ref, {
            location: new GeoPoint(coordinates.lat, coordinates.lng),
            city: city,
          });

        } else {
          console.error(
            `Unable to get coordinates or city for ${data.fullName}`
          );
        }
      }
    });

    
  } catch (error) {
    console.error("Error fetching and processing records:", error);
  }
}

async function getLocationFromAddress(address) {
  try {
    const results = await geocodingService.geocode(address);
    const cityComponent = results?.[0]?.address_components.find((component) =>
      component.types.includes("locality")
    );
    const city = cityComponent ? cityComponent.long_name : null;
    const geometry = results?.[0]?.geometry?.location;

    return geometry && city
      ? { coordinates: { lat: geometry.lat, lng: geometry.lng }, city }
      : {};
  } catch (error) {
    console.error("Error geocoding address:", error);
    return {};
  }
}
