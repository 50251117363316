import React, { useState, useContext } from "react";
import { getAuth, updateProfile } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/authProvider";
import { sendEmailVerification } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SignUpPage() {
  const { createUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    password: "",
  });

  function handleOnSubmit(e) {
    e.preventDefault();
    const auth = getAuth();
    createUser(formData.email, formData.password)
      .then((result) => {
        sendEmailVerification(auth.currentUser);
        updateProfile(auth.currentUser, { displayName: formData.fullName });
        navigate("/login");
        toast("User Created: " + result.user.email);
      })
      .catch((error) => {
        toast("Error: " + error.message);
      });
    e.target.reset();
  }
  return (
    <div className="centered-container">
      <div className="userForm">
        <form onSubmit={handleOnSubmit}>
          <h3>Sign Up</h3>
          <label>Full Name</label>
          <br />
          <input
            type="text"
            name="Name"
            placeholder="Full Name"
            required
            value={formData.fullName}
            onChange={(e) =>
              setFormData({ ...formData, fullName: e.target.value })
            }
          />
          <br />
          <label>Email</label>
          <br />
          <input
            type="email"
            name="email"
            placeholder="Email"
            required
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
          <br />
          <label>Password</label>
          <br />
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={formData.password}
            required
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
          />
          <br />
          <button type="submit">Register</button>

          <p>
            Already have an account?
            <button
              onClick={() => {
                navigate("/login");
              }}>
              Login
            </button>
          </p>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default SignUpPage;
